import styled from '@emotion/styled';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { useShopStoreIncontext } from '../../../context/shopContext';
import TickIcon from '../../../images/tick.svg'
import XMarkIcon from '../../../images/xmark.svg'
import Link from '../../Link';

const TableRowDesktop = styled.tr`
  border-bottom: ${(props) => !props.noBorder ? `1px solid #CCCCCC` : ''};
`
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Objectivity';
  max-width: 1400px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const MobileTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Objectivity';
  @media (min-width: 1025px) {
    display: none;
  }
`;
const ImageCell = styled.td`
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  flex: 1;
  z-index: 2;
  @media (max-width: 1024px) {
    min-width: 100vw;
  }

  @media (min-width: 1025px) {
    width: ${(props) => props.width ? `calc(1400px / ${props.width})` : `10vw;`};
    max-width: 400px;
  }
`;
const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin: auto;
  justify-content: center;
  text-align: start;
  max-width: 1400px;
  @media (min-width: 1025px) {
    justify-content: start;
  }
`;
const Title = styled.div`
font-size: 22px;
font-weight: 500;
text-align: center;
min-height: 80px;
@media (max-width: 1024px) {
  font-size: 18px; /* Adjust font size for responsiveness */
  min-height: auto; /* Remove minimum height on smaller screens */
}
`;
const SelectorTitle = styled.div`
  font-size: 20px;
  font-weight: 500;

  color: grey;

  &.selected {
    color: black;
  }
`;
const Price = styled.span`
font-size: 20px;
font-weight: 500;
letter-spacing: -1px;
`;
const TableRow = styled.tr`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 100vw;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    flex-direction: row;
    padding-bottom: 24px;
  }
  border-bottom: ${(props) => !props.noBorder ? `1px solid #CCCCCC` : ''};
`;
const TableCellStatic = styled.th`
    flex: 1;
    padding: 16px;
    font-size: 18px;
    display: flex;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    text-align: start;
    letter-spacing: -1px;

    @media (min-width: 1025px) {
      width: 200px;
      min-height: 100px;
      justify-content: start;
    }

    @media (max-width: 1024px) {
      padding: 24px;
      width: 100%;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: ${(props) => !props.noBorder ? `1px solid #CCCCCC` : ''};
    }
`;
const TableTitleCellStatic = styled.td`
    flex: 1;
    padding: 0 24px;
    font-size: ${(props) => props.comparePage ? `34px` : '52px'};
    padding-top: ${(props) => props.comparePage ? `5vh` : '15vh'};
    font-weight: 600;
    align-items: end;
    justify-content: center;
    text-align: start;
    letter-spacing: -1px;
    max-height: 200px;

    @media (min-width: 1025px) {
      width: 200px;
      min-height: 100px;
      justify-content: start;
    }

    @media (max-width: 1024px) {
      width: 100%;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: ${(props) => !props.noBorder ? `1px solid #CCCCCC` : ''};
    }
`;
const HeaderImage = styled(GatsbyImage)`
  object-fit: cover;
  @media (max-width: 1024px) {
    width: 100%; /* Adjust image width for responsiveness */
    height: auto; /* Ensure aspect ratio is maintained */
}
`;
const LogoImage = styled(GatsbyImage)`
  object-fit: cover;
  width: 50%;
  height: 100%;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 70%;
    height: 100%;
    margin: 16px auto;
  }
`;
const ScrollableRowContainerDesktop = styled.tr`
    overflow-x: auto;
    max-width: 100vw;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f4f4f4;
    overflow-y: hidden;
    position: sticky;
    top: 0;
    background: white;
`;
const ScrollLogoImage = styled(GatsbyImage)`
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;
const ScrollableRowContainer = styled.tr`
    display: flex;
    overflow-x: auto;
    width: 100vw;
    border: 1px solid #80808030;
    padding: 4px 16px;
    scrollbar-width: thin;
    scrollbar-color: #ccc #f4f4f4;
    overflow-y: hidden;
    cursor: pointer;
    position: sticky;
    top: ${(props) => props.toggleOn ? `80px` : '0px'};;
    background: white;
`;
const ScrollableItem = styled.td`
    min-width: 125px;
    width: 125px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: ${(props) => props.height ? `${props.height}px` : '100px'};
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: -1px;
    background: ${(props) => (props.selected ? `#D3D3D3;` : `#fff`)};
    border-radius: 40px;
    text-align: center;
`;
const StyledLink = styled(Link)`
  position: relative;
  display: flex;
  color: #000;
  flex: 1 1 0%;
  background: ${(props) => (props.selected ? `#d9d9d9;` : `#f7f6f4`)};
  padding: 8px;
  border-radius: 10px;
  margin: 2px;
  width: 95px;
  align-items: center;

  &::after {
    position: absolute;
    content: '';
    top: 4px;
    right: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    background: ${(props) => (props.selected ? `#464646;` : `#fff`)};
    border: 1px solid ${(props) => (!props.selected ? `#d9d9d9;` : `#f7f6f4`)};
  }

  @media (max-width: 1025px) {
    width: 30vw;
    padding: 0;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`
const MobileFontSize = styled.div`
  font-size: 12px;
  @media (max-width: 1025px) {
    font-size: 11px;
  }
`

const localeLookup = {
  'en': 'US',
  'en-NZ': 'NZ',
  'en-AU': 'AU',
  'en-GB': 'UK',
}
const currencyLookup = {
  'en': '$',
  'en-NZ': '$',
  'en-AU': '$',
  'en-GB': '£',
}

const ProductTable = ({ data }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageXOffset + window.innerWidth - 25); // Adjust as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const { storeLocale } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
    }
  })
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  const products = data.TableColumnData.map(({ TableCell, OptionalImage, OptionalTitle, OptionalPrice, LinkTo, OptionalTitleImage }) => ({
    image: OptionalImage?.file,
    name: OptionalTitle,
    price: OptionalPrice,
    link: LinkTo,
    logoImage: OptionalTitleImage?.file,
    productInfo: TableCell,
  }));

  const featureLength = Array.from({ length: products[0].productInfo.length }, (_, i) => i);

  const [selectedIndex, setSelectedIndex] = useState(0)
  const handleItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <TableContainer comparePage={data?.TableTitle.toLowerCase().includes('compare')}>
      <Table>
        <tbody>
          <TableRowDesktop noBorder={true}>
            <TableTitleCellStatic comparePage={data?.TableTitle.toLowerCase().includes('compare')} style={{ width: '30%', minWidth: 200, minHeight: 200, textAlign: 'start', fontWeight: 600, letterSpacing: '-2px' }}>
              {data.TableTitle}
            </TableTitleCellStatic>
            {products?.map((product, index) => (
              <td key={index} style={{ width: '30%', minWidth: 200 }}>
                <Link to={product.link}>
                  <HeaderImage
                    image={withArtDirection(
                      getImage(product.image?.childImageSharp?.gatsbyImageData),
                      [
                        {
                          media: '(max-width: 1024px)',
                          image: getImage(
                            product.image?.childImageSharp?.gatsbyImageDataMobile || product.image?.childImageSharp?.gatsbyImageDataMobile
                          ),
                        },
                      ]
                    )}
                    style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                    alt={product.name}
                  />
                </Link>
              </td>
            ))}
          </TableRowDesktop>
          <ScrollableRowContainerDesktop>
            <td style={{ paddingTop: 20, width: 300 }}>
              {currentUrl.includes('compare') && data.TableToggle.length > 0 && (
                <div style={{ margin: '16px auto', display: 'flex' }}>
                  {data.TableToggle.map((item, key) => (
                    <th key={key} style={{ padding: 4, borderRadius: 25, color: currentUrl.includes(item.to) ? '#000' : '#7b7b7a', fontWeight: currentUrl.includes(item.to) ? 600 : 500, fontSize: 14 }}>
                      {item.ProductTitle}
                      <MobileFontSize style={{ borderRadius: 25 }}>
                        {item.FeatureItems.map((feature, index) => (
                          <div style={{ display: 'flex', marginBottom: 5, alignItems: 'center' }} key={index}>
                            <p>{feature.FeatureDescription}</p>
                          </div>
                        ))}
                      </MobileFontSize>
                    </th>
                  ))}
                </div>
              )}
            </td>
            {products?.map((product, index) => (
              <td key={index} style={{ minHeight: 100, verticalAlign: product?.logoImage ? 'bottom' : 'middle', paddingTop: 20 }}>
                {
                  product?.logoImage ? (
                    <LogoImage
                      image={withArtDirection(
                        getImage(product?.logoImage?.childImageSharp?.gatsbyImageData),
                        [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(
                              product?.logoImage?.childImageSharp?.gatsbyImageDataMobile ? product?.logoImage?.childImageSharp?.gatsbyImageDataMobile : product?.logoImage?.childImageSharp?.gatsbyImageDataMobile
                            ),
                          },
                        ]
                      )}
                      style={{ objectFit: 'contain' }}
                      alt={product?.name}
                    />
                  ) : (
                    <Link to={product?.link}><Title dangerouslySetInnerHTML={{ __html: product?.name }} /></Link>
                  )
                }
              </td>
            ))}
          </ScrollableRowContainerDesktop>
          <TableRowDesktop>
            <TableCellStatic style={{ padding: 0 }}></TableCellStatic>
            {products?.map((product, index) => (
              <td key={index} style={{ minHeight: 100, padding: '20px 0', textAlign: 'center' }}>
                {
                  product.price && (
                    <Price>{currencyLookup[storeLocale]}<span>{product.price}{" "}{localeLookup[storeLocale]}</span></Price>
                  )
                }
              </td>
            ))}
          </TableRowDesktop>
          {featureLength.map((featureNum, index) => (
            <TableRowDesktop key={index}>
              <TableCellStatic sideBorder={true}>{products[selectedIndex].productInfo[featureNum]?.Title}</TableCellStatic>
              {products?.map((product, index) => (
                <td key={index} style={{ padding: '20px 0', textAlign: 'center' }}>
                  {
                    product.productInfo[featureNum].Body !== null ? (
                      <>
                        <span dangerouslySetInnerHTML={{ __html: product.productInfo[featureNum]?.Body && product.productInfo[featureNum]?.Body }} />
                        {
                          product.productInfo[featureNum].Condition && (
                            <img src={TickIcon} alt="tick" style={{ width: 20, height: 20, margin: '10px auto' }} />
                          )
                        }
                      </>
                    ) : (
                      <>
                        {
                          product.productInfo[featureNum].Condition ? (
                            <img src={TickIcon} alt="tick" style={{ width: 20, height: 20, margin: '0 auto' }} />
                          ) : (
                            <img src={XMarkIcon} alt="xmark" style={{ width: 20, height: 20, margin: '0 auto' }} />
                          )
                        }
                      </>
                    )
                  }
                </td>
              ))}
            </TableRowDesktop>
          ))}
        </tbody>
      </Table>
      <MobileTable>
        <tbody>
          <tr>
            <TableCellStatic style={{ padding: 0, minWidth: 0, minHeight: 0, textAlign: 'center' }} noBorder={true}>
              {currentUrl.includes('compare') && (
                <th style={{ fontSize: 28, padding: '0 60px', paddingBottom: 20, fontWeight: 500, letterSpacing: '-1.39px' }}>{data.TableTitle}</th>
              )}
            </TableCellStatic>
          </tr>
            <ScrollableRowContainer style={{ justifyContent: !currentUrl.includes('compare') && 'center' }}>
              {products.map((product, index) => (
                <ScrollableItem
                  key={index}
                  width={product?.logoImage && 46}
                  height={product?.logoImage && 50}
                  className={index === selectedIndex ? 'selected' : ''}
                  selected={index === selectedIndex && product?.logoImage ? 'selected' : ''}
                  onClick={() => handleItemClick(index)}
                >
                  {product?.logoImage ? (
                    <ScrollLogoImage
                      image={withArtDirection(
                        getImage(product?.logoImage?.childImageSharp?.gatsbyImageData),
                        [
                          {
                            media: '(max-width: 1024px)',
                            image: getImage(
                              product?.logoImage?.childImageSharp?.gatsbyImageDataMobile || product?.logoImage?.childImageSharp?.gatsbyImageDataMobile
                            ),
                          },
                        ]
                      )}
                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                      alt={product?.name}
                    />
                  ) : (
                    <SelectorTitle dangerouslySetInnerHTML={{ __html: product.name }} className={index === selectedIndex ? 'selected' : ''} />
                  )}
                </ScrollableItem>
              ))}
            </ScrollableRowContainer>
          <tr>
            <ImageCell>
              <Link to={products[selectedIndex]?.link}>
                <HeaderImage
                  image={withArtDirection(
                    getImage(products[selectedIndex]?.image?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          products[selectedIndex]?.image?.childImageSharp?.gatsbyImageDataMobile ? products[selectedIndex]?.image?.childImageSharp?.gatsbyImageDataMobile : products[selectedIndex]?.image?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )}
                  style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                  alt={products[selectedIndex]?.name}
                />
              </Link>
            </ImageCell>
          </tr>
          <tr>
            <TableCellStatic mobileOn={true} style={{ display: 'block', textAlign: 'center' }}>
              {products[selectedIndex]?.logoImage ? (
                <LogoImage
                  image={withArtDirection(
                    getImage(products[selectedIndex]?.logoImage?.childImageSharp?.gatsbyImageData),
                    [
                      {
                        media: '(max-width: 1024px)',
                        image: getImage(
                          products[selectedIndex]?.logoImage?.childImageSharp?.gatsbyImageDataMobile ? products[selectedIndex]?.logoImage?.childImageSharp?.gatsbyImageDataMobile : products[selectedIndex]?.logoImage?.childImageSharp?.gatsbyImageDataMobile
                        ),
                      },
                    ]
                  )}
                  style={{ objectFit: 'contain', height: 'auto' }}
                  alt={products[selectedIndex]?.name}
                />
              ) : (
                <span dangerouslySetInnerHTML={{ __html: products[selectedIndex]?.name }} />
              )}
              {products[selectedIndex]?.price && (
                <Price>{currencyLookup[storeLocale]}<span>{products[selectedIndex]?.price}{" "}{localeLookup[storeLocale]}</span></Price>
              )}
            </TableCellStatic>
          </tr>
          {featureLength.map((featureNum, index) => (
            <tr key={index}>
              <TableCellStatic mobileOn={true} style={{ textAlign: 'center' }} noBorder={true}>{products[selectedIndex].productInfo[featureNum]?.Title}</TableCellStatic>
              <TableRow>
                {products[selectedIndex].productInfo[featureNum].Body !== null ? (
                  <td sideBorder={true} key={index} style={{ paddingTop: 0, textAlign: 'center' }}>
                    {products[selectedIndex].productInfo[featureNum]?.Condition && (
                      <img src={TickIcon} alt="tick" style={{ width: 20, height: 20, margin: '10px' }} />
                    )}
                    <span dangerouslySetInnerHTML={{ __html: products[selectedIndex].productInfo[featureNum]?.Body }} />
                  </td>
                ) : (
                  <td key={index} style={{ paddingTop: 0, textAlign: 'center' }}>
                    {products[selectedIndex].productInfo[featureNum].Condition ? (
                      <img src={TickIcon} alt="tick" style={{ width: 20, height: 20 }} />
                    ) : (
                      <img src={XMarkIcon} alt="xmark" style={{ width: 20, height: 20 }} />
                    )}
                  </td>
                )}
              </TableRow>
            </tr>
          ))}
        </tbody>
      </MobileTable>
    </TableContainer >
  );
};
export default ProductTable;
